<template>
    <div class="contact-us">
        <page-header id="header" title="Contact us"/>
        <section id="social">
            <social-links-for-contactus/>
        </section>
        <section id="contact-form">
            <contact-form/>
        </section>
        <section id="direction-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63362.886704425946!2d79.88092299345755!3d6.988017807632739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae258f8f4d32b1d%3A0xd4c5670681ffe436!2sSt%20Joseph&#39;s%20Church!5e0!3m2!1sen!2slk!4v1587304360112!5m2!1sen!2slk" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </section>
        <subscribe-youtube id="subscribe-youtube"/>
        <section id="subscribe-newsletter">
            <newsletter-subscription-form/>
        </section>
    </div>
</template>

<script>
    import PageHeader from "../components/comm/PageHeader";
    import SocialLinksForContactus from "../components/contact/SocialLinksForContactus";
    import ContactForm from "../components/contact/ContactForm";
    import SubscribeYoutube from "../components/comm/SubscribeYoutube";
    import NewsletterSubscriptionForm from "../components/comm/NewsletterSubscriptionForm";

    export default {
        name: "Contact",
        components: {
            NewsletterSubscriptionForm, SubscribeYoutube, ContactForm, SocialLinksForContactus, PageHeader
        }
    }
</script>

<style lang="scss" scoped>
    @import "~@/assets/scss/_variables";

     #header, #social, #contact-form, #direction-map{
        margin-bottom: 2rem;
    }

    #social{
        display: none;
    }

    #contact-form{
        margin-top: 4rem;
    }

    #direction-map{
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 4rem;
        padding: 0px;
    }

    #subscribe-newsletter{
        margin-top: 0px;
        margin-bottom: 15rem;
    }
    @media (min-width: 768px) {
        #social{
            display: unset;
        }
    }
    @media (min-width: 1200px) {
        #subscribe-newsletter{
            margin-bottom: 10rem;
        }

        #direction-map{
            padding: 0 5rem;
        }
    }
</style>
