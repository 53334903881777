<template>
    <div class="contact-form">
        <div class="contact-form__img-Wrapper">
            <img src="~@/assets/img/contact/contact_us.jpg" alt="">
        </div>
        <div class="contact-form__fromWrapper">
            <form method="post"
                  name="contact-us-form"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  @submit.prevent="handleSubmit"
            >
                <p class="hidden">
                    <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
                </p>
                <div class="contact-form__input ft-name">
                    <lable>First Name</lable>
                    <input name="contactFName" type="text" placeholder="First Name" required v-model="form.contactFName"/>
                </div>
                <div class="contact-form__input lt-name">
                    <lable>Last Name</lable>
                    <input name="contactLName" type="text" placeholder="Last Name" v-model="form.contactLName"/>
                </div>
                <div class="contact-form__input email">
                    <lable>Email Address</lable>
                    <input name="contactEmail" type="email" placeholder="Your email address" v-model="form.contactEmail"/>
                </div>
                <div class="contact-form__input message">
                    <lable>Message</lable>
                    <textarea name="message" :placeholder="`   Message`" v-model="form.message"/>
                </div>
                <div class="contact-form__input submit">
                    <button type="submit">Send</button>
                </div>
            </form>
        </div>

        <sweet-modal
                ref="cont__modal"
                @close="clearfield"
                icon="success"
        >
            <h1>Thank you we will take further action based on your message.</h1>
        </sweet-modal>
    </div>
</template>
<script>
    import {SweetModal} from "sweet-modal-vue"
    export default {
        name: 'contact-form',
        components: {SweetModal},
        data(){
            return {
                form: {
                    contactFName: '',
                    contactLName: '',
                    contactEmail: '',
                    message: '',
                }
            }
        },
        methods: {
            clearfield(){
                this.form = {
                    contactFName: '',
                    contactLName: '',
                    contactEmail: '',
                    message: '',
                }
            },
            encode(data) {
                return Object.keys(data)
                    .map(
                        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                    )
                    .join("&");
            },
            handleSubmit(e) {
                let that = this;
                // fetch("/", {
                //     method: "POST",
                //     headers: {"Content-Type": "application/x-www-form-urlencoded"},
                //     body: this.encode({
                //         "form-name": "contact-us-form",
                //         ...this.form
                //     })
                // }).then(()=> that.$refs.cont__modal.open())

              setTimeout(() => that.$refs.cont__modal.open(), 500)


            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/_variables";

    .contact-form {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &__img-Wrapper {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__fromWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 2rem;

            form {
                display: flex;
                flex-wrap: wrap;

            }

            .hidden{
                display: none;
            }
        }

        &__input {
            margin-bottom: 1rem;
            width: 100%;

            label {
                font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                -webkit-font-smoothing: antialiased !important;
                line-height: 1.625;
                box-sizing: border-box;
                border: 0 solid #e2e8f0 /* 2 */
            ;
                display: block !important;
                font-weight: 700 !important;
                margin-bottom: .5rem !important;
                color: #4a5568 !important;
                font-size: .875rem !important;
            }

            input {
                -webkit-font-smoothing: antialiased !important;
                box-sizing: border-box;
                border: 0 solid #e2e8f0 /* 2 */
            ;
                font-family: inherit;
                font-size: 100%;
                /* 2 */
            ;
                overflow: visible;
                -webkit-appearance: none !important;
                border-radius: .25rem !important;
                border-width: 1px !important;
                line-height: 1.25 !important;
                padding: .5rem .75rem !important;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
                color: #4a5568 !important;
                width: 100% !important;
                margin: .5rem 0 0;
            }

            textarea {
                -webkit-font-smoothing: antialiased !important;
                box-sizing: border-box;
                border: 0 solid #e2e8f0 /* 2 */
            ;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06) !important;
                font-family: inherit;
                font-size: 100%;
                margin: .5rem 0 0;
                overflow: auto;
                padding: 0;
                line-height: inherit;
                color: inherit;
                border-radius: .25rem !important;
                border-width: 1px !important;
                resize: both !important;
                width: 100%;
                height: 8rem;
            }

            button {
                -webkit-font-smoothing: antialiased !important;
                box-sizing: border-box;
                border: 0 solid #e2e8f0 /* 2 */
            ;
                font-family: inherit;
                font-size: 100%;
                margin: 0 /* 2 */
            ;
                overflow: visible;
                text-transform: none;
                -webkit-appearance: button;
                background-image: none;
                cursor: pointer;
                line-height: inherit;
                background-color: $primary;
                border-radius: .25rem !important;
                font-weight: 700 !important;
                padding: .5rem 1rem !important;
                color: #fff !important;
            }

            button:hover {
                background-color: $dark;
            }
        }
    }

    @media (min-width: 768px) {
        .contact-form {
            justify-content: space-between;

            &__img-Wrapper {
                width: 50% !important;
            }

            &__fromWrapper {
                width: 40% !important;
                margin-top: 0px;
            }
        }

        .ft-name, .lt-name {
            width: 45%;
        }

        .lt-name {
            margin-left: 10%;
        }

    }
</style>
