<template>
    <div class="container">
        <div class="social fb">
            <div class="social__icon">
                <i class="fab fa-facebook-square"></i>
            </div>
            <div class="social__connection">
                <a :href="$store.state.contact[0].fb" target="_blank">Facebook</a>
            </div>
        </div>
        <div class="social ytd">
            <div class="social__icon">
                <i class="fab fa-youtube"></i>
            </div>
            <div class="social__connection">
                <a :href="$store.state.contact[0].yt" target="_blank">Youtube</a>
            </div>
        </div>
        <div class="social inst">
            <div class="social__icon">
                <i class="fab fa-instagram-square"></i>
            </div>
            <div class="social__connection">
                <a :href="$store.state.contact[0].insta" target="_blank">Instagram</a>
            </div>
        </div>
        <div class="social twet">
            <div class="social__icon">
                <i class="fab fa-twitter"></i>
            </div>
            <div class="social__connection">
                <a :href="$store.state.contact[0].twitter" target="_blank">Twitter</a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'social-links-for-contactus'
    }
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/_variables";
    .container {
        display: none;
    }


    @media (min-width: 1200px) {
        .container {
            position: relative;
            display: flex;
            justify-content: space-between;
        }
        .social {
            display: flex;
            margin-right: 2rem;
            margin-left: 2rem;
            cursor: pointer;
            justify-content: start;
            align-content: start;

            &__icon{
                font-size: 3rem;
                color: $primary;
            }

            &__connection {
                margin-left: 1rem;
                padding-top: 1.1rem;
                position: relative;

                a {
                    box-sizing: border-box;
                    color: $primary;
                    text-decoration: none;
                    font-weight: bold;
                }
            }
        }

        .social:hover {
            &__icon {
                color: #da5c1b;
            }

            &__connection {
                a {
                    color: #da5c1b;
                }
            }
        }
    }
</style>
